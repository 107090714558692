<template>
  <!--
    The view for the InstallationWebhookList-component.
    Displays all installation webhooks.
  -->
  <Portlet
    title="SLA"
    class="sla"
    icon="file-contract"
  >
    <template slot="buttons">
      <select v-model="quartal">
        <option :value="null">
          Yearly
        </option>
        <option value="Q1">
          Q1
        </option>
        <option value="Q2">
          Q2
        </option>
        <option value="Q3">
          Q3
        </option>
        <option value="Q4">
          Q4
        </option>
      </select>
      <select
        v-model="year"
        class="ml-2"
      >
        <option
          v-for="(yr, index) in years"
          :key="`years-${ index }`"
          :value="yr"
        >
          {{ yr }}
        </option>
      </select>
    </template>
    <InstallationSlaList 
      ref="list"
      :installation-id="installationId"
      :quartal="quartal"
      :year="year"
    />
  </Portlet>
</template>

<script>
export default {
  name: "InstallationDetailSla",
  components: {
    InstallationSlaList: () => import('@/components/Installation/InstallationSlaList.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      quartal: null,
      year: null,
      years: null
    }
  },
  created () {
    var today = new Date();
    var quarter = Math.floor((today.getMonth() + 3) / 3);
    this.quartal = `Q${quarter}`;

    this.year = new Date().getFullYear();
    let years = [];
      for (var i = this.year; i >= this.year - 2; i--) {
        years.push(i);
      }
    this.years = years;
  }
}
</script>